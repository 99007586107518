
// icons reference https://www.flaticon.com/free-icon
const IMPROVEMENT_AREAS_DETAILS = {
    "CONTINUOUS_TESTING": {
        "label": "Continuous Testing",
        "description": <>
            Continuous Testing is the practice of testing software throughout the entire software development lifecycle to ensure that it meets the desired quality standards. This involves the use of both automated and manual testing techniques to identify defects, vulnerabilities, and performance issues early on in the development process.
            <br />
            <br />
            To implement Continuous Testing, organisations should focus on building a comprehensive testing strategy that includes unit testing, integration testing, functional testing, and performance testing. This strategy should also incorporate automated testing tools and techniques, such as test automation frameworks and continuous integration and delivery pipelines, to increase testing efficiency and effectiveness.
            <br />
            <br />
            Common pitfalls when implementing Continuous Testing include:
            <br />
            <br />
            <ul>
                <li>Focusing too much on automation and neglecting manual testing</li>
                <li>Not involving all stakeholders in the testing process</li>
                <li>Neglecting to create a robust testing strategy</li>
                <li>Failing to address technical debt and test code quality</li>
                <li>Not adequately tracking and reporting on testing results</li>
            </ul>
            At RiverSafe, our Continuous Testing service is designed to help organisations improve the quality of their software and reduce the risk of defects and vulnerabilities. Our experienced DevOps engineers work closely with clients to identify areas where testing can be improved and implement a comprehensive testing strategy that includes both automated and manual testing techniques. By catching issues early in the development process, we help clients save time and resources and improve the overall quality of their software.</>,
        "image": "/assets/improvements/testing.png"
    },
    "CLOUD_INFRASTRUCTURE": {
        "label": "Cloud Infrastructure",
        "description": <>
            Cloud Infrastructure refers to the resources and services needed to support and manage cloud-based applications, including compute, storage, networking, and security resources. This includes cloud platforms such as AWS, Asure, and Google Cloud, as well as infrastructure-as-code tools and techniques.
            <br />
            <br />
            To implement Cloud Infrastructure, organisations should focus on designing and implementing a scalable, secure, and reliable cloud architecture that meets their specific needs and requirements. This includes selecting the appropriate cloud platform, designing the network topology and security policies, and implementing infrastructure-as-code practices to manage and automate infrastructure deployment and maintenance.
            <br />
            <br />
            Common pitfalls when implementing Cloud Infrastructure include:
            <br />
            <br />
            <ul>
                <li>Failing to properly plan and design the cloud architecture</li>
                <li>Neglecting to implement robust security measures</li>
                <li>Overlooking cost optimisation opportunities</li>
                <li>Failing to adequately manage and monitor the cloud infrastructure</li>
                <li>Not properly managing cloud resource allocation</li>
            </ul>
            At RiverSafe, Our Cloud Infrastructure service is designed to help you optimise your cloud resources, reduce infrastructure costs, and improve the performance and availability of your applications. Our team of experienced DevOps engineers will work closely with you to understand your business requirements and design a customised cloud infrastructure solution that meets your needs. We can help you migrate your applications to the cloud, configure and manage your cloud infrastructure, and implement best practices for security, compliance, and disaster recovery. We have expertise in a variety of cloud platforms, including AWS, Asure, and Google Cloud, and can provide guidance on selecting the right platform and services for your specific needs.</>,
        "image": "/assets/improvements/infrastructure.png"
    },
    "CODE_MAINTAINABILITY": {
        "label": "Code Maintainability",
        "description": <>
            Code Maintainability refers to the ease with which code can be modified, enhanced, and fixed throughout its lifecycle. It encompasses various aspects of code quality, such as readability, simplicity, modularity, and testability, and is critical for ensuring long-term software sustainability and maintainability.
            <br />
            <br />
            To implement Code Maintainability, organisations should focus on establishing best practices for code development and management, such as code review and refactoring, automated testing, and continuous integration and delivery. They should also invest in tools and techniques that help maintain code quality, such as static code analysis, code coverage analysis, and code metrics tracking.
            <br />
            <br />
            Common pitfalls when implementing Code Maintainability include:
            <br />
            <br />
            <ul>
                <li>Failing to establish coding standards and guidelines</li>
                <li>Neglecting to conduct regular code reviews and refactorings</li>
                <li>Overlooking the importance of automated testing and code coverage analysis</li>
                <li>Not prioritising code quality and maintainability in the development process</li>
                <li>Neglecting to train and educate developers on best practices for code maintainability</li>
            </ul>
            At RiverSafe, our Code Maintainability service is designed to help you improve the quality and maintainability of your codebase. Our experienced DevOps engineers will work closely with you to conduct a thorough code review, identify areas of improvement, and develop a customised plan for enhancing code quality and maintainability. We use a combination of manual and automated testing and analysis techniques to ensure that your codebase meets industry standards for quality and maintainability. Our goal is to help you reduce technical debt, accelerate development cycles, and improve the overall sustainability of your software applications.</>,
        "image": "/assets/improvements/optimization.png"
    },
    "CONTINUOUS_INTEGRATION": {
        "label": "Continuous integration",
        "description": <>
            Continuous Integration (CI) is a software development practice that involves frequently integrating code changes into a shared repository and automatically testing and building the application to detect and resolve integration issues as early as possible. The goal of CI is to provide developers with rapid feedback on changes and enable them to detect and address integration issues before they become more complex and costly.
            <br />
            <br />
            To implement Continuous Integration, organisations should focus on the following key practices:
            <br />
            <br />
            <ul>
                <li>Frequent and automated code commits</li>
                <li>Automated build and test processes</li>
                <li>Integration of code changes into a shared repository</li>
                <li>Early detection and resolution of issues</li>
            </ul>
            Common pitfalls when implementing Continuous Integration include:
            <br />
            <br />
            <ul>
                <li>Failing to properly plan and design the CI pipeline</li>
                <li>Neglecting to integrate automated testing and quality checks</li>
                <li>Not adequately monitoring the pipeline for errors or issues</li>
                <li>Failing to establish clear communication and collaboration between developers and operations teams</li>
            </ul>
            At RiverSafe, our Continuous Integration service is designed to help you accelerate your development processes and improve the quality of your code by implementing a reliable and efficient CI pipeline. Our team of experienced DevOps engineers will work closely with you to understand your development processes and identify areas where we can improve efficiency and effectiveness. We can help you select the right tools and technologies, design and implement the CI pipeline, and integrate automated testing and quality checks. Our approach to Continuous Integration involves designing pipelines that are flexible, scalable, and easy to maintain, allowing you to quickly adapt to changing requirements and improve your time-to-market</>,
        "image": "/assets/improvements/integration.png"
    },
    "CONTINUOUS_DELIVERY": {
        "label": "Continuous delivery",
        "description": <>
            Continuous Delivery is the practice of automating the software delivery process to ensure that software can be reliably and quickly released to production. It involves a set of practices and principles that enable organisations to deploy software updates frequently, reliably, and with minimal manual effort.
            <br />
            <br />
            To implement Continuous Delivery, organisations should focus on the following key areas:
            <br />
            <br />
            <ul>
                <li>Automation of the build, testing, and deployment process</li>
                <li>Creation of a robust and scalable deployment pipeline</li>
                <li>Ensuring that the software is always deployable and ready for release</li>
                <li>Implementing continuous monitoring and feedback mechanisms</li>
                <li>Ensuring the deployment process is repeatable and auditable</li>
            </ul>
            Common pitfalls when implementing Continuous Delivery include:
            <br />
            <br />
            <ul>
                <li>Failing to invest in automation and tooling</li>
                <li>Neglecting to design a robust deployment pipeline</li>
                <li>Not prioritising quality assurance and testing</li>
                <li>Not adequately monitoring the production environment</li>
                <li>Failing to establish clear communication and collaboration between development and operations teams</li>
            </ul>
            At RiverSafe, we provide a comprehensive Continuous Delivery service designed to help organisations automate their software delivery process, reduce the time to market, and minimise the risk of errors and downtime. Our team of experienced DevOps engineers can help you build a scalable and reliable deployment pipeline that enables you to deploy software updates with confidence. We can help you automate the entire delivery process, from code changes to production deployment, and ensure that your software is always deployable and ready for release. We can also help you implement best practices for continuous monitoring and feedback, ensuring that your software remains stable and reliable in production.</>,
        "image": "/assets/improvements/DeployFrequency.png"
    },
    "ARCHITECTURE": {
        "label": "Architecture",
        "description": <>
            Architecture is a crucial aspect of software development that can significantly impact the performance and scalability of applications. From a DORA perspective, a loosely coupled architecture is preferred as it enables teams to work independently and deliver value to the organisation quickly. This type of architecture allows for testing and deploying applications on demand, without requiring orchestration with other services.
            <br />
            <br />
            To implement a loosely coupled architecture, organisations should focus on analysing their existing architecture and identifying opportunities for improvement. It involves creating an architecture that enables teams to work independently without relying on other teams for support and services. Microservices architecture is one approach that is commonly used and enables greater flexibility and agility in development.
            <br />
            <br />
            Common pitfalls when implementing architecture include:
            <br />
            <br />
            <ul>
                <li>Failing to analyse and plan the architecture adequately</li>
                <li>Not leveraging best practices</li>
                <li>Not prioritising the performance and scalability of the applications</li>
            </ul>
            At RiverSafe, our team of experienced DevOps engineers will work closely with you to analyse your existing architecture and identify opportunities for improvement. We can help you create a loosely coupled architecture that enables your teams to work independently and deliver value to the organisation quickly. By leveraging our expertise in architecture and related tools, we can help you achieve significant improvements in the performance and scalability of your applications. Our Architecture service enables you to create a software development environment that is designed to support the needs of your organisation, both now and in the future.</>,

        "image": "/assets/improvements/architechture.png"
    },
    "LEARNING_CULTURE": {
        "label": "Learning culture",
        "description": <>
            A learning culture is an essential component of successful DevOps practices. It involves creating an environment where employees are encouraged to learn and grow, take risks, and continuously improve their skills and knowledge.
            <br />
            <br />
            To implement a learning culture in your organisation, you should focus on the following key areas:
            <br />
            <br />
            <ul>
                <li>Promote a growth mindset: Emphasise the importance of continuous learning and growth, and encourage employees to embrace challenges and failures as opportunities for growth.</li>
                <li>Encourage experimentation: Provide employees with opportunities to experiment with new ideas and technologies, and allow them to fail and learn from their mistakes.</li>
                <li>Foster collaboration and knowledge-sharing: Encourage teamwork and collaboration, and create opportunities for employees to share knowledge and expertise.</li>
                <li>Provide opportunities for skill development: Offer training, mentorship, and other opportunities for employees to develop new skills and improve existing ones.</li>
                <li>Celebrate learning and success: Recognise and celebrate employee achievements and successes, and use them as opportunities to share knowledge and inspire others.</li>
            </ul>
            Common pitfalls when implementing a learning culture include:
            <br />
            <br />
            <ul>
                <li>Failing to provide adequate resources and support for learning and development.</li>
                <li>Neglecting to provide clear goals and expectations for learning and growth.</li>
                <li>Failing to foster a safe and supportive environment for experimentation and learning.</li>
                <li>Not prioritising knowledge sharing and collaboration.</li>
                <li>Failing to measure and track progress towards learning goals.</li>
            </ul>
            At RiverSafe, we understand the importance of a learning culture in successful DevOps practices. As part of our DevOps consulting services, we can help your organisation create a culture of continuous learning and improvement. Our team of experienced consultants can provide training, mentorship, and other resources to help your employees develop new skills and improve existing ones. We can also help you create opportunities for experimentation, knowledge sharing, and collaboration, and measure progress towards your learning goals. </>,
        "image": "/assets/improvements/network.png"
    },
    "WORK_IN_PROCESS_LIMITS": {
        "label": "Work in process limits",
        "description": <>
            Work in process (WIP) limits is a practice in DevOps that aims to improve the efficiency of software development processes. It involves setting a maximum limit on the number of work items that can be in progress at any given time in a development cycle.
            <br />
            <br />
            WIP limits help teams to focus on completing tasks that are already in progress before moving on to new tasks. By limiting the number of tasks in progress, WIP limits encourage teams to complete tasks more quickly and reduce the time it takes to deliver features to users.
            <br />
            <br />
            To implement WIP limits, teams should consider the following key areas:
            <br />
            <br />
            <ul>
                <li>Identifying the stages in their development process where WIP limits will be implemented</li>
                <li>Setting the WIP limit for each stage based on team capacity and other constraints</li>
                <li>Monitoring and enforcing the WIP limit to ensure that work items are not exceeding the maximum limit</li>
                <li>Identifying and addressing bottlenecks in the development process to ensure that WIP limits are effective</li>
            </ul>
            Common pitfalls when implementing WIP limits include:
            <br />
            <br />
            <ul>
                <li>Setting WIP limits too high, which can result in reduced efficiency and longer lead times</li>
                <li>Failing to monitor and enforce WIP limits effectively, which can result in work items exceeding the maximum limit</li>
                <li>Not addressing bottlenecks in the development process, which can prevent teams from completing work items within the WIP limit</li>
            </ul>
            At RiverSafe, we provide a comprehensive DevOps consulting service that includes WIP limits as a key practice. Our team of experienced DevOps engineers can help you identify the stages in your development process where WIP limits should be implemented and set appropriate limits based on your team's capacity and other constraints. We can also help you monitor and enforce WIP limits effectively, identify and address bottlenecks in your development process, and ensure that your team is delivering features to users more efficiently.</>,
        "image": "/assets/improvements/ChangeFailPercentage.png"
    },
    "TRUNK_BASED_DEVELOPMENT": {
        "label": "Trunk-based development",
        "description": <>
            Trunk-based development is a software development approach that emphasises the use of a single code trunk or branch, with developers committing their changes to the main branch multiple times a day. This approach contrasts with traditional branching models, which often involve long-lived feature branches and only merging changes back into the main branch when a feature is completed.
            <br />
            <br />
            Trunk-based development enables developers to work more collaboratively and to catch integration issues earlier in the development process. It also promotes continuous integration and delivery, as code changes are merged frequently and automatically tested.
            <br />
            <br />
            To implement trunk-based development, organisations should focus on the following key areas:
            <br />
            <br />
            <ul>
                <li>Establishing a robust testing framework and automated build process to ensure that changes are always deployable</li>
                <li>Providing clear guidelines and processes for developers to commit their changes to the main branch</li>
                <li>Promoting a culture of collaboration and communication among developers to prevent conflicts and encourage effective teamwork</li>
                <li>Ensuring that code reviews are conducted regularly and that issues are addressed promptly</li>
            </ul>
            Common pitfalls when implementing trunk-based development include:
            <br />
            <br />
            <ul>
                <li>Failing to establish clear guidelines and processes for committing changes to the main branch, which can lead to conflicts and instability</li>
                <li>Neglecting to prioritise testing and quality assurance, which can result in errors and bugs</li>
                <li>Not providing adequate training and support to developers, which can lead to resistance to change and poor adoption</li>
                <li>Failing to promote collaboration and communication among developers, which can result in silos and conflicts</li>
                <li>Not adapting to the unique needs and challenges of the organisation, which can result in poor outcomes and dissatisfaction with the approach</li>
            </ul>
            At RiverSafe, we can help organisations adopt this approach and achieve the benefits of increased collaboration, faster delivery, and improved quality. Our team of experienced DevOps engineers can help you establish the necessary testing and build processes, provide guidance and training to developers, and promote a culture of collaboration and communication. We can also help you identify and address common pitfalls and adapt the approach to the unique needs of your organisation.</>,
        "image": "/assets/improvements/git.png"
    },
    "WESTRUM_ORGANISATIONAL_CULTURE": {
        "label": "Westrum Organisational Culture",
        "description": <>
            Westrum Organisational Culture is a model for understanding how an organisation's culture can impact its performance and outcomes. The model was developed by Dr. Ron Westrum and is based on his research into safety culture in healthcare organisations.
            <br />
            <br />
            Westrum identified three types of organisational culture, which he named pathological, bureaucratic, and generative. Pathological cultures are characterised by low trust, blame, and a focus on individual interests over organisational goals. Bureaucratic cultures are focused on following rules and procedures, with a strong emphasis on hierarchy and control. Generative cultures, on the other hand, prioritise collaboration, continuous learning, and adaptability to changing circumstances.
            <br />
            <br />
            The Westrum Organisational Culture model is relevant to DevOps because it emphasises the importance of creating a culture that supports continuous improvement and innovation. To implement this culture, organisations should focus on fostering trust, encouraging collaboration, and empowering employees to take ownership of their work.
            <br />
            <br />
            Common pitfalls when trying to create a generative culture include:
            <br />
            <br />
            <ul>
                <li>Focusing too much on process and not enough on people and relationships</li>
                <li>Failure to empower employees to take ownership of their work</li>
                <li>Lack of transparency and communication</li>
                <li>Resistance to change from employees or leadership</li>
                <li>Not providing adequate resources and training to support cultural change</li>
            </ul>
            At RiverSafe, we understand the importance of creating a generative culture that supports DevOps principles and practices. Our DevOps consulting services are designed to help organisations identify areas for improvement and implement changes that promote collaboration, transparency, and continuous learning. We work closely with our clients to understand their unique needs and challenges, and develop tailored solutions that are aligned with their goals and values. Whether you are just starting your DevOps journey or looking to take your existing DevOps practices to the next level, we can help you achieve success by fostering a culture of collaboration, trust, and innovation.</>,
        "image": "/assets/improvements/organisation.png"
    },
    "MONITORING_AND_OBSERVABILITY": {
        "label": "Monitoring and Observability",
        "description": <>
            Monitoring and observability are essential practices for modern software development and operations. They involve collecting and analysing data from applications and infrastructure to gain insight into their behavior and performance. This data is used to identify issues, diagnose problems, and improve the overall health of the system.
            <br />
            <br />
            Monitoring is the practice of collecting and measuring data from a system, such as the performance of a web application, the availability of a database, or the response time of an API. This data is typically displayed on dashboards and alerts are triggered when certain thresholds are exceeded.
            <br />
            <br />
            Observability, on the other hand, is the practice of collecting and analysing data to gain insight into the behavior of a system. This includes not just metrics, but also logs and traces. Logs provide detailed information about what is happening inside the system, while traces provide a view of how requests are moving through the system. By combining these different sources of data, engineers can gain a comprehensive understanding of how the system is performing and how it can be improved.
            <br />
            <br />
            To implement monitoring and observability in a software development project, organisations should focus on the following key areas:
            <br />
            <br />
            <ul>
                <li>Instrumentation: This involves adding code to applications and infrastructure to collect data about their behavior and performance. This data can then be used for monitoring and observability.</li>
                <li>Collection and storage: This involves collecting data from applications and infrastructure and storing it in a central location for analysis.</li>
                <li>Analysis and visualisation: This involves using tools to analyse the collected data and visualise it in a way that is useful for engineers and other stakeholders.</li>
                <li>Alerting and incident response: This involves setting up alerts that trigger when certain thresholds are exceeded, and defining processes for responding to incidents.</li>
            </ul>
            Common pitfalls when implementing monitoring and observability include:
            <br />
            <br />
            <ul>
                <li>Failing to define clear objectives and metrics for monitoring</li>
                <li>Overlooking critical parts of the system, such as third-party services or network infrastructure</li>
                <li>Neglecting to set up proper alerting and incident response processes</li>
                <li>Collecting too much data or not enough relevant data</li>
                <li>Failing to use appropriate tools or failing to configure them properly</li>
            </ul>
            At RiverSafe, we provide comprehensive monitoring and observability services that help organisations gain deep insights into the behavior and performance of their systems. Our team of experienced DevOps engineers can help you implement best practices for instrumentation, data collection and storage, analysis and visualisation, alerting, and incident response. We can help you choose the right tools for your specific needs and configure them to meet your requirements. Contact us today to learn more about how our monitoring and observability services can help you improve the health and performance of your systems.</>,
        "image": "/assets/improvements/monitor.png"
    },
    "WORKING_IN_SMALL_BATCHES": {
        "label": "Working in small batches",
        "description": <>
            Working in small batches is a software development practice that involves breaking down work into smaller, manageable pieces and completing them in a sequential order. This approach is designed to minimise the risks and delays associated with larger and more complex changes by providing more frequent opportunities for feedback and testing.
            <br />
            <br />
            To implement working in small batches, teams should focus on the following key areas:
            <br />
            <br />
            <ul>
                <li>Breaking down work into small, well-defined pieces: This allows for work to be completed more quickly and enables teams to better manage the flow of work through their development process.</li>
                <li>Establishing clear criteria for moving work from one stage to the next: This helps to ensure that work is properly tested and validated at each stage of the process before it is moved to the next stage.</li>
                <li>Developing a reliable and repeatable process for managing work: This includes establishing clear roles and responsibilities, defining workflows, and using tools and systems to track progress and identify potential bottlenecks.</li>
                <li>Prioritising frequent communication and collaboration: Working in small batches requires close coordination between team members and frequent feedback from stakeholders to ensure that work is aligned with the needs of the organisation.</li>
            </ul>
            Common pitfalls when implementing working in small batches include:
            <br />
            <br />
            <ul>
                <li>Failing to establish clear criteria for moving work between stages: This can lead to work being moved through the process too quickly, without proper testing and validation.</li>
                <li>Overcomplicating workflows and processes: This can create unnecessary delays and confusion, and may make it difficult to identify and address bottlenecks in the process.</li>
                <li>Neglecting to prioritise communication and collaboration: This can result in work being completed without proper alignment with the needs of the organisation, leading to rework and delays.</li>
            </ul>
            At RiverSafe, we can help organisations implement best practices for working in small batches. Our team of experienced DevOps engineers can help you establish clear criteria for moving work through your development process, develop reliable and repeatable workflows, and prioritise communication and collaboration between team members and stakeholders. </>,
        "image": "/assets/improvements/scrum.png"
    },
}

export default IMPROVEMENT_AREAS_DETAILS;
