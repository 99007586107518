import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Container className="text-center" style={{ marginTop: "60px", marginBottom: "40px" }}>
      <h1>Oops!</h1>
      <br />
      <h2>We can't seem to find the page you are looking for.</h2>
      <div style={{ marginTop: "50px" }} />
      <h3 style={{ color: "var(--danger)", fontSize: "1.9em" }}>
        <b>Error 404 - Page Not Found</b>
      </h3>

      <br />
      <Row className="text-start">
        <p>Here are some helpful links you can try instead:</p>
        {/* <li><Link to="/signup" style={{ color: "var(--gray)" }}>Sign up</Link></li>
            <li><Link to="/login" style={{ color: "var(--gray)" }}>Sign in</Link></li>
            <li><Link to="/forgotPassword" style={{ color: "var(--gray)" }}>Forgot Password</Link></li> */}
        {/* TODO: define if verification should be exposed in list or not */}
        {/* <li><Link to="/verification" style={{ color: "var(--gray)" }}>Verify User</Link></li> */}
        <li>
          <Link to="/quiz" style={{ color: "var(--gray)" }}>
            Quiz
          </Link>
        </li>
        <li>
          <Link to="/results" style={{ color: "var(--gray)" }}>
            Results
          </Link>
        </li>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
