import React, { useState } from 'react'
import { useVerifyUserEmail, useResendVerificationCode } from '../lib/auth-helper';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom'
import './VerificationPage.scss'
import { useShowAlert } from '../lib/alert';


const VerificationPage = () => {
    const navigate = useNavigate()
    const [searchParams,] = useSearchParams()
    const showAlert = useShowAlert()

    // TODO: catch when email is empty?
    const email = searchParams.get('email')

    const [verificationCode, setVerificationCode] = useState("");
    const [validated, setValidated] = useState(false);
    const verifyEmail = useVerifyUserEmail()
    const resendVerificationCode = useResendVerificationCode()

    const verificationCodeError =
        verificationCode.length !== 6
            ? 'Code must be 6-digit long.'
            : ''
    const errors = [
        verificationCodeError
    ].filter(i => i)

    const verifyUserClick = (event) => {
        setValidated(true)
        event.preventDefault();
        event.stopPropagation();

        if (errors.length > 0) return;

        verifyEmail(email, verificationCode).then(r => {
            navigate('/login')
        }).catch((err) => {
            showAlert(err.message)
        })
    }


    const resendCodeClick = () => {
        resendVerificationCode(email).then(r => {
            showAlert("Code resent successfully, check your email", "Success")
        }).catch((error) => {
            showAlert("Resent failed, invalid email")
        })
    }

    const handleChange = (setState) =>
        (event) => {
            setState(event.target.value);
        }

    return (
        <>
            <Container className='verify-user'>
                <Row style={{ minHeight: "100%" }} className="justify-content-md-center">
                    <Col>
                        <Stack className='col-md-6 mx-auto col-sm-4' gap={4}>
                            <h2 className='text-center'> Welcome to the DevOps Maturity Tool </h2>
                            <Form noValidate onSubmit={verifyUserClick}>
                                <h4 className="text-center">
                                    Email Verification
                                </h4>
                                <p className="text-center" style={{ fontSize: '1.5rem' }}>We sent a 6-digit verification code over to <b>{email}</b>, please check your email and enter the code in the box below to confirm it's you.</p>
                                <br />
                                <Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="verificationFormCode">
                                    <Col sm={8} md={12} lg={8}>
                                        <Form.Control
                                            style={{ letterSpacing: (verificationCode.length > 1 ? "1rem" : "0rem") }}
                                            type="number"
                                            value={verificationCode}
                                            onChange={handleChange(setVerificationCode)}
                                            placeholder="Enter verification code"
                                            isInvalid={verificationCodeError && validated}
                                            isValid={!verificationCodeError && validated}
                                        />
                                        <Form.Control.Feedback type="invalid" style={{ fontSize: '0.6em' }}>
                                            {verificationCodeError}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <div className="text-center">
                                    <Button style={{ width: "300px", marginTop: "1rem" }} variant="primary" type="submit">
                                        Verify me
                                    </Button>
                                </div>
                            </Form>
                            <div className="text-center justify-content-md-center">
                                Didn't receive a code? &nbsp;
                                <Button className="btn-secondary resend-btn" style={{ fontSize: '1.2rem' }} onClick={resendCodeClick}>Resend a code</Button>
                            </div>
                        </Stack>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default VerificationPage
