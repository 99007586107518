import { gql, useQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { createSubmission } from "../graphql/mutations";
import { getQuiz } from "../graphql/queries";
import { useUserAttributes } from "../lib/auth-helper";
import { STORAGE_KEYS, useStorageValue } from "../lib/storage";
import Question from "./Question";
import "./Quiz.scss";
import ReactGA4 from "react-ga4";

const QUIZ_SLUG = "devops-maturity";
const questionsSource = "Source: 2022 Accelerate State of DevOps, Google";

const Quiz = () => {
  const { setValue: setLastQuizResult } = useStorageValue(STORAGE_KEYS.LAST_QUIZ_RESULT);
  const { email } = useUserAttributes();
  const { loading, error, data } = useQuery(gql(getQuiz), {
    variables: { slug: QUIZ_SLUG, version: 1 }
  });
  const [scoreValues, setScoreValues] = useState([]);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const [submitAnswers] = useMutation(gql(createSubmission));
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const [startTime, setStartTime] = useState(new Date());

  const quiz = data?.getQuiz;

  useEffect(() => {
    if (quiz) {
      setScoreValues(quiz.questions.map(() => null));
    }
  }, [quiz]);

  if (error) {
    return (
      <>
        <h3 className="text-center" style={{ color: "var(--danger)", marginTop: "60px" }}>
          {error.message}
        </h3>
      </>
    );
  }

  if (loading || scoreValues.length === 0) {
    return <></>;
  }

  const updateAnswer = (questionIdx, value) => {
    updateTimeSpent(questionIdx);
    setScoreValues((answers) => answers.map((v, idx) => (idx === questionIdx ? value : v)));
  };
  const sendAnswers = () => {
    const nowDate = new Date().toISOString();
    const quizResponse = {
      quizSlug: quiz.slug,
      quizVersion: quiz.version,
      submissionBy: email, //hashed email
      submissionDate: nowDate,
      submittedQuizAnswers: quiz.questions.map((question, questionIdx) => ({
        question: question.question,
        scoreValue: scoreValues[questionIdx],
        answer: question.answers.filter((a) => a.scoreValue === scoreValues[questionIdx])[0].answer,
      })),
    };

    submitAnswers({ variables: { input: quizResponse } }).then((e) => {
      setLastQuizResult(e.data.createSubmission);
      navigate("/results");
    });
  };

  const handlePreviousButton = () => {
    setStartTime(new Date());
    setCurrentQuestionIdx(currentQuestionIdx - 1);
  };

  const handleNextButton = () => {
    setStartTime(new Date());
    setCurrentQuestionIdx(currentQuestionIdx + 1);
  };

  const updateTimeSpent = (currentIdx) => {
    const now = new Date();
    const ga4Object = {
      label: "quiz_qs" + (currentIdx + 1),
      category: "quiz",
      action: "time_spent_on_question",
      params: {
        question_index: currentIdx + 1,
        time_spent: ((now - startTime) / 1000).toFixed(2),
      },
    };

    ReactGA4.event(ga4Object);
    setStartTime(new Date());
  };

  const question = quiz.questions[currentQuestionIdx];
  const questionsCount = quiz.questions.length;
  return (
    <div className="quiz">
      <div className="back-div">
      <Link data-testid="back-to-main" id="back-action" to='/'>
          {`< Back to main page`}
      </Link>
      </div>
      <Container className="question-container">
        <Row>
          <Question
            currentIdx={currentQuestionIdx}
            header={`Question ${currentQuestionIdx + 1} of ${questionsCount}`}
            title={question.metric}
            text={question.question}
            questionsSource={questionsSource}
            answers={question.answers}
            value={scoreValues[currentQuestionIdx]}
            onChange={(value) => updateAnswer(currentQuestionIdx, value)}
            onPreviousClick={handlePreviousButton}
            onNextClick={handleNextButton}
            onSendAnswers={sendAnswers}
            questionsCount={questionsCount}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Quiz;
