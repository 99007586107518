import React from "react";
import { Card, Form, Stack, ProgressBar, Button, Row, Col } from "react-bootstrap";
import "./Question.scss";
import RiversafeQuizLogo from "./RiversafeQuizLogo";

const Question = ({
  currentIdx,
  header,
  title,
  text,
  questionsSource,
  answers,
  onChange,
  value,
  onNextClick,
  onPreviousClick,
  onSendAnswers,
  questionsCount,
}) => {
  const handleOptionChange = (event) => {
    onChange(Number(event.target.value));
  };

  const currentQuestionAnswered = value != null;
  return (
    <Form>
      <Row>
        <RiversafeQuizLogo />
      </Row>
      <Row className="justify-content-md-center">
        <Col lg={6} className="question-card">
          <Card style={{ padding: "15px" }}>
            <Card.Body>
              <Col xs={6} md={12} className="align-self-center">
                <ProgressBar now={((currentIdx + 1) / questionsCount) * 100} />
              </Col>
              <Card.Title>
                <h3>{title.toUpperCase()} </h3>
              </Card.Title>
              <Card.Text>
                {text}
                <br />
                <span className="reference">{questionsSource}</span>
              </Card.Text>
              <Col lg={12}>
                <Stack gap={2}>
                  {answers.map((x, idx) => (
                    <Form.Check key={idx} name="question" tabIndex="1">
                      <Form.Check.Label className={{ checked: x.scoreValue === value }}>
                        <Form.Check.Input
                          type="radio"
                          value={x.scoreValue}
                          checked={x.scoreValue === value}
                          onChange={handleOptionChange}
                        ></Form.Check.Input>
                        &nbsp;&nbsp;{x.answer}
                      </Form.Check.Label>
                    </Form.Check>
                  ))}
                  <Row className="nav-buttons justify-items-md-center">
                    <Col xs={6} md={6} className="text-start">
                      {currentIdx === 0 ? null : (
                        <Button className="left" onClick={onPreviousClick}>
                          Previous
                        </Button>
                      )}
                    </Col>
                    
                    <Col xs={6} md={6} className="text-end">
                      {currentIdx === questionsCount - 1 ? (
                        <Button
                          className="right"
                          disabled={!currentQuestionAnswered}
                          onClick={onSendAnswers}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          className="right"
                          disabled={!currentQuestionAnswered}
                          onClick={onNextClick}
                        >
                          Next
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Stack>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default Question;
