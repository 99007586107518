import React, { useState } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { getDmtScoreForUser, getDmtROI } from "../graphql/queries"; // Import your GraphQL queries
import "./roiCalculator.scss";
import { Container, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ROICalculator = () => {
    const [businessSize, setBusinessSize] = useState('S');
    const [productBusinessSize, setProductBusinessSize] = useState('');
    const [email, setEmail] = useState('');
    const [roiResult, setRoiResult] = useState(null)

    var scoreLevelResponse = ""
    const { loading, error: scoreError, data: scoreData } = useQuery(gql(getDmtScoreForUser), {
        skip: email.length < 1,
        variables: { email: email },
    });
    if (scoreError) {
        console.error("Response error from getDmtScoreForUser. {", scoreError.message, "}")
    }
    else {
        scoreLevelResponse = scoreData?.getDmtScoreForUser;
    }

    const [fetchDmtROI, {called, loading: loadingROI, error: errorROI, data }] = useLazyQuery(gql(getDmtROI), {
        variables: {
            scoreLevel: scoreLevelResponse,
            staffSize: businessSize,
            revenue: productBusinessSize || '',
        },
    });

    const calculateROIClick = async () => {
        if(scoreLevelResponse){
            const response = await fetchDmtROI()
            if(response.error){
                console.error(response.error)
                setRoiResult(null)
            }
            else {
                const result = response?.data?.getDmtROI
                ? Number(response?.data?.getDmtROI.toFixed(2)).toLocaleString('en')
                :  null;
                setRoiResult(result)
            }
        }
    }

    return (
        <Container>
            <div className="roi-page-header">
            <h2>Return on Investment Calculator</h2>
            </div>
            <div className="d-flex">
                <div className="roi-form">
                    <Form.Group controlId="businessSize" className="roi-form-group">
                        <Form.Label>
                            Business Size{' '}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-business-size">
                                        Business size represents your staff size and the number of business lines within your organisation.
                                    </Tooltip>
                                }
                            >
                                <i className="bi bi-question-circle"></i>
                            </OverlayTrigger>
                        </Form.Label>
                        <Form.Control className="form-select" as="select" value={businessSize} onChange={(e) => setBusinessSize(e.target.value)}>
                            <option value="S">Small (1-50)</option>
                            <option value="M">Medium (51-150)</option>
                            <option value="L">Large (151-1000)</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="productBusinessSize" className="roi-form-group">
                        <Form.Label>
                            Product Business Size (Monetary Value){' '}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-product-size">
                                        This represents the estimated monetary value of your product portfolio.
                                    </Tooltip>
                                }
                            >
                                <i className="bi bi-question-circle"></i>
                            </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            value={productBusinessSize}
                            onChange={(e) => setProductBusinessSize(e.target.value)}
                            step="0.01"
                            min="0"
                        />
                    </Form.Group>

                    <Form.Group controlId="email" className="roi-form-group">
                        <Form.Label>
                            Email Address
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-email">
                                        We use the email address to retrieve your DevOps Maturity Quiz results.
                                    </Tooltip>
                                }
                            >
                                <i className="bi bi-question-circle"></i>
                            </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="primary" onClick={calculateROIClick} data-testid="calculate-btn" disabled={!scoreLevelResponse}>
                        Calculate ROI
                    </Button>
                </div>
                <div className="roi-result">
                    <p><strong>Return on Investment</strong></p>
                    <p>The ROI calculator estimates potential returns of a DevOps transformation based on many pieces of data that we attain from averages.
                        We only need a few things from you to complete the calculation.
                    </p>

                    <p>Please note:</p>
                    <ul>
                        <li>Calculations are estimations using average data.</li>
                        <li>Actual ROI may vary due to real-world factors.</li>
                    </ul>
                    <p>For a more accurate assessment, you can find the information you need <a href="https://services.google.com/fh/files/misc/whitepaper_roi_of_devops_transformation_2020_google_cloud.pdf" target="_blank" rel="noreferrer">here</a>, or contact us directly.</p>
                    <hr />
                    {roiResult && (
                        <p className="roi-result-text">
                            ROI Result: {roiResult !== null ? `£${roiResult}` : 'N/A'}
                        </p>
                    )}
                </div>
            </div>
    </Container>);
};

export default ROICalculator;
