import { useState } from "react";

const STORAGE = {}
const useStorageValue = (key) => {
    const [value, setValueInternal] = useState(STORAGE[key])
    return {
        value: value,
        setValue: (newValue) => {
            STORAGE[key] = newValue
            setValueInternal(newValue)
        }
    }
}

const STORAGE_KEYS = {
    LAST_QUIZ_RESULT: 'LAST_QUIZ_RESULT'
}

export {
    STORAGE_KEYS,
    useStorageValue
}
