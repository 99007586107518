/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDmtROI = /* GraphQL */ `
  query GetDmtROI($revenue: Float!, $scoreLevel: String!, $staffSize: String!) {
    getDmtROI(revenue: $revenue, scoreLevel: $scoreLevel, staffSize: $staffSize)
  }
`;
export const getDmtScoreForUser = /* GraphQL */ `
  query GetDmtScoreForUser($email: String!) {
    getDmtScoreForUser(email: $email)
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($slug: String!, $version: Int!) {
    getQuiz(slug: $slug, version: $version) {
      description
      name
      questions {
        answers {
          answer
          scoreValue
          __typename
        }
        metric
        question
        __typename
      }
      scoringModel {
        scoreLevel
        scoreSuggestions
        scoreThresholdPercentage
        __typename
      }
      slug
      version
      __typename
    }
  }
`;
export const getQuizSubmissions = /* GraphQL */ `
  query GetQuizSubmissions($email: String!) {
    getQuizSubmissions(email: $email) {
      quizSlug
      quizVersion
      scoreLevel
      scorePercentage
      scoreSuggestions
      submissionBy
      submissionDate
      submissionId
      submittedQuizAnswers {
        answer
        question
        scoreValue
        __typename
      }
      __typename
    }
  }
`;
export const listQuizzes = /* GraphQL */ `
  query ListQuizzes(
    $filter: TableQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        description
        name
        questions {
          answers {
            answer
            scoreValue
            __typename
          }
          metric
          question
          __typename
        }
        scoringModel {
          scoreLevel
          scoreSuggestions
          scoreThresholdPercentage
          __typename
        }
        slug
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
