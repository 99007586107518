function RiversafeQuizLogo() {

    return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem"}}>
        <img
            src="/assets/devops-quiz-title.svg"
            id="quiz-title"
            alt="quiz-title"
        />
    </div>
    )
}

export default RiversafeQuizLogo