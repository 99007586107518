import React, { useState } from "react";
import { useResetPassword } from "../lib/auth-helper";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Container, InputGroup, Row, Stack } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ResetPassword.scss";
import { useShowAlert } from "../lib/alert";
import BackButton from "../components/BackButton";

const ResetPassword = () => {
  const navigate = useNavigate();
  const resetPassword = useResetPassword();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const showAlert = useShowAlert();

  const [verificationCode, setVerificationCode] = useState("");
  const [username, setUsername] = useState(email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");

  const verificationCodeError = verificationCode.length !== 6 ? "Code must be 6-digit long." : "";
  const passwordError = password ? null : "Please enter a password";

  const confirmPasswordError =
    password === confirmPassword ? null : "Password and Confirm password must match";

  const errors = [verificationCodeError, passwordError, confirmPasswordError].filter((i) => i);

  const resetPasswordClick = (event) => {
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if (errors.length > 0) {
      return;
    }

    resetPassword(username, verificationCode, password)
      .then(async (r) => {
        setPasswordReset(true);
        await new Promise((r) => setTimeout(r, 4000));
        navigate("/login");
      })
      .catch((e) => showAlert(e.message, "Error"));
  };

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const togglePassword = () => {
    setPasswordInputType((currentType) => {
      return currentType === "password" ? "text" : "password";
    });
  };

  return (
    <>
      <Container fluid md={{ span: 12 }} className="custom-border">
        <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 2 }}>
          <BackButton redirectTo="login" />
        </Col>
        <Container className="reset-password" style={{ marginTop: "50px", marginBottom: "40px" }}>
          <Row style={{ minHeight: "100%" }} className="align-items-center">
            <Col>
              {!passwordReset ? (
                <Stack className="col-md-6 mx-auto" gap={5}>
                  <h2 style={{ textAlign: "center" }}>Reset Password</h2>
                  <Form
                    className="justify-items-center"
                    validated={false}
                    noValidate
                    onSubmit={resetPasswordClick}
                  >
                    <p className="reset-password-text">
                      {" "}
                      An email has been sent to <b>{username}</b>. Please check your inbox or spam
                      and enter the code that was sent to your email with instructions to reset your
                      password.
                    </p>
                    <Form.Group
                      className="mb-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1"
                      controlId="formVerificationCode"
                    >
                      <Col>
                        <Form.Control
                          style={{
                            letterSpacing: verificationCode.length > 1 ? "1rem" : "0rem",
                            textAlign: "center",
                          }}
                          type="number"
                          value={verificationCode || ""}
                          onChange={handleChange(setVerificationCode)}
                          placeholder="Enter verification code"
                          isInvalid={verificationCodeError && validated}
                          isValid={!verificationCodeError && validated}
                        />
                        <Form.Control.Feedback type="invalid" style={{ fontSize: "0.6em" }}>
                          {verificationCodeError}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1"
                      controlId="formEmail"
                    >
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        value={username || ""}
                        onChange={handleChange(setUsername)}
                        placeholder="Email address"
                        disabled
                      />
                    </Form.Group>
                    <br />
                    <Row>
                      <Col xs={12} lg={7}>
                        <Form.Group
                          className="mb-3 col-lg-12 offset-lg-4 "
                          controlId="formPassword"
                        >
                          <Form.Label>New password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={passwordInputType}
                              value={password || ""}
                              onChange={handleChange(setPassword)}
                              placeholder="Enter password"
                              isInvalid={passwordError && validated}
                              isValid={!passwordError && validated}
                            />
                            <InputGroup.Text>
                              <i
                                data-testid="passwordView"
                                className={
                                  passwordInputType === "password"
                                    ? "bi bi-eye-slash-fill"
                                    : "bi bi-eye-fill"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={togglePassword}
                              ></i>
                            </InputGroup.Text>
                          </InputGroup>

                          {passwordError && validated && (
                            <Form.Control.Feedback type="invalid" style={{ fontSize: "0.6em" }}>
                              {passwordError}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Col xs={12} lg={12} className="v1 mb-3 col-lg-12 offset-lg-4">
                          <p>Your new secure password must comply with the following:</p>
                          <li>Minimum 8 characters long</li>
                          <li>At least one uppercase letter</li>
                          <li>At least one lowercase letter</li>
                          <li>At least one special character</li>
                          <li>At least one number</li>
                          <br />
                        </Col>
                        <Form.Group
                          className="mb-3 col-lg-12 offset-lg-4"
                          controlId="formConfirmPassword"
                        >
                          <Form.Label>Confirm password</Form.Label>
                          <Form.Control
                            type="password"
                            value={confirmPassword}
                            onChange={handleChange(setConfirmPassword)}
                            placeholder="Confirm password"
                            isInvalid={confirmPasswordError && validated}
                            isValid={!confirmPasswordError && validated}
                          />
                          <Form.Control.Feedback type="invalid" style={{ fontSize: "0.6em" }}>
                            {confirmPasswordError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs={12}
                        sm={10}
                        md={12}
                        lg={12}
                        className="d-grid justify-content-center"
                      >
                        <Button
                          className="reset-password-submit-btn"
                          style={{
                            width: "300px",
                            marginTop: "2rem",
                            marginBottom: "2rem",
                          }}
                          variant="primary"
                          type="submit"
                        >
                          Change password
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Stack>
              ) : (
                <Stack className="col-md-6 mx-auto" gap={5}>
                  <h2 style={{ textAlign: "center" }}>Password Reset Complete</h2>

                  <div className="text-center">
                    <p>
                      <b>Your password has been updated. You are all set to log in!</b>
                    </p>
                    <p>You will be redirected now .....</p>
                  </div>
                </Stack>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ResetPassword;
