import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, Stack, Button, InputGroup } from "react-bootstrap";
import { useSignIn, SignInWithGoogleButton, SignInWithLinkedinButton } from "../lib/auth-helper";
import { useShowAlert } from "../lib/alert";
import { Link, useNavigate } from "react-router-dom";
import { checkValidEmail } from "../lib/utils";

const SignIn = () => {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const showAlert = useShowAlert();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");

  const signInClick = (event) => {
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if (errors.length > 0) {
      return;
    }
    signIn(username, password)
      .then(() => {
        navigate("/quiz");
      })
      .catch((e) => {
        if (e.message === "User is not confirmed.") {
          showAlert("User is not confirmed yet", "Error");
          navigate(`/verification?email=${username}`);
        } else {
          showAlert(e.message);
        }
      });
  };

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const emailError = !username
    ? "Please enter an email address."
    : checkValidEmail(username)
    ? null
    : "Email should be valid. (i.e. john@domain.com)";

  const passwordError = password ? null : (
    <>
      Please enter a password. <Link to="/forgotPassword">(forgot it?)</Link>
    </>
  );

  const errors = [emailError, passwordError].filter((i) => i);
  const togglePassword = () => {
    setPasswordInputType((currentType) => {
      return currentType === "password" ? "text" : "password";
    });
  };

  return (
    <>
      <Container style={{ display: "contents" }}>
        <Row style={{ minHeight: "87%", marginBottom: "30px" }} className="align-items-center">
          <Col>
            <h2>Sign in</h2>
            <Stack className="col-md-12 col-lg-8 col-xl-7 mx-auto" gap={2}>
              <Form validated={validated} noValidate onSubmit={signInClick}>
                <Form.Group className="mb-3" controlId="signinFormEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    role="email"
                    value={username}
                    onChange={handleChange(setUsername)}
                    placeholder="Enter email"
                    required
                    isValid={!emailError && validated}
                    isInvalid={emailError && validated}
                  />
                  <Form.Control.Feedback type="invalid" style={{ fontSize: "0.6em" }}>
                    {emailError}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{ fontSize: "0.6em" }}>
                    Looks good.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="signinFormPassword">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={passwordInputType}
                      role="password"
                      value={password}
                      onChange={handleChange(setPassword)}
                      placeholder="Enter password"
                      required
                    />
                    <InputGroup.Text>
                      <i
                        data-testid="passwordView"
                        className={
                          passwordInputType === "password"
                            ? "bi bi-eye-slash-fill"
                            : "bi bi-eye-fill"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={togglePassword}
                      ></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {passwordError && validated ? (
                    <Form.Control.Feedback type="invalid" style={{ fontSize: "0.6em" }}>
                      {passwordError}
                    </Form.Control.Feedback>
                  ) : (
                    <div style={{ textAlign: "end", fontSize: "0.6em", marginTop: "0.25rem" }}>
                      <Link to="/forgotPassword">Forgot password?</Link>
                    </div>
                  )}
                </Form.Group>
                <div className="d-grid gap-2">
                  <Button variant="primary" type="submit" disabled={!!!errors.length}>
                    Submit
                  </Button>
                </div>
              </Form>

              <div className="divider d-flex align-items-center my-3">
                <p className="text-center mx-3 mb-0">or</p>
              </div>
            </Stack>
            <Row className="gap-2 text-center justify-content-md-center">
              <Col xs={12} md={{ span: 7 }} lg={8} xl={{ span: 4 }}>
                <SignInWithGoogleButton type="standard" />
              </Col>
              <Col xs={12} md={{ span: 7 }} lg={8} xl={{ span: 4 }}>
                <SignInWithLinkedinButton />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{ bottom: "40px", textSize: "0.5rem", position: "relative", marginBottom: "1rem" }}
        >
          <Col className="text-center">
            <p>
              Don't have an account yet?&nbsp;&nbsp;<Link to="/signup">Register now</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignIn;
