import "./custom.scss";
import React, { useEffect, useState } from "react";
import { ProtectedLayout, useSignIn, useIsSignedIn } from "./lib/auth-helper";
import { Route, Routes } from "react-router-dom";
import { AlertContainer } from "./lib/alert";
import LoginPage from "./pages/LoginPage";
import QuizPage from "./pages/QuizPage";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import VerificationPage from "./pages/VerificationPage";
import ResultsPage from "./pages/ResultsPage";
import NotFoundPage from "./pages/NotFoundPage";
import ROICalculator from "./components/RoiCalculator";
import ReactGA4 from "react-ga4";
import TopNavBar from "./components/TopNavBar";
import { obfuscatePassword } from "./lib/utils";

const measurementId = "G-XDCWSCGSSH";
ReactGA4.initialize(measurementId);

function App() {
  const signIn = useSignIn();
  const isSignedIn = useIsSignedIn();
  const username = process.env.REACT_APP_GUEST_USER_EMAIL || "";
  const password = obfuscatePassword(username);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const maxLoginAttempts = 3;

  useEffect(() => {
    if (!isSignedIn && loginAttempts < maxLoginAttempts) {
      signIn(username, password)
        .then(() => {
          console.log("Logged in as service account user");
        })
        .catch((e) => {
          console.error("Sign-in error:", e.message);
          setLoginAttempts((prev) => prev + 1); // Increment login attempts
        });
    }
  }, [isSignedIn, loginAttempts]);

  return (
    <AlertContainer>
      <TopNavBar />
      <Routes>
        <Route path="/root" element={<LoginPage login />} />
        <Route path="*" element={<NotFoundPage />} />

        {/* Not logged users */}
        <Route element={<ProtectedLayout noUser redirectTo="/" />}>
          <Route path="/login" element={<LoginPage login />} />
          <Route path="/signup" element={<LoginPage signup />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Route>
        {/* Logged, registered users */}
        <Route element={<ProtectedLayout needsUser redirectTo="/login" />}>
          <Route path="/" element={<QuizPage />} />
          <Route path="/quiz" element={<QuizPage />} />
          <Route path="/roiCalculator" element={<ROICalculator />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/verification" element={<VerificationPage />} />
        </Route>
      </Routes>
    </AlertContainer>
  );
}
export default App;
