import React, { useState } from "react";
// import "./FollowUp.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useRegisterUser } from "../lib/auth-helper";
import { useShowAlert } from "../lib/alert";
import { checkValidEmail, isWorkEmail, obfuscatePassword } from "../lib/utils";
import { gql, useMutation } from "@apollo/client";
import { createSubmission } from "../graphql/mutations";
import { STORAGE_KEYS, useStorageValue } from "../lib/storage";

function FollowUp(props) {
  const submittedData = props.data;
  const [email, setEmail] = useState("");
  const showAlert = useShowAlert();
  const [validated, setValidated] = useState(false);
  const [submitAnswers] = useMutation(gql(createSubmission));
  const { setValue: setLastQuizResult } = useStorageValue(STORAGE_KEYS.LAST_QUIZ_RESULT);

  const userPassword = obfuscatePassword("ABcdEF.1"); 

  const emailError =
    checkValidEmail(email) && isWorkEmail(email)
      ? null
      : "Email should be a corporate one. (i.e. john@work-domain.com).";

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const registerUser = useRegisterUser();

  const registerUserClick = async (event) => {
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if (emailError) {
      return;
    }

    let registerUserParams = {
      username: email,
      password: userPassword,
    };

    await sendAnswers();
    await registerUser(registerUserParams)
      .then(({ userRegistered }) => {
        // creates user with no groups associated and unconfirmed
        showAlert("Submission successful!", "Success");
      })
      .catch((error) => {
        if (error.code === "UsernameExistsException") {
          console.log("User registered but submission completed!");
        } else {
          showAlert("Invalid request");
        }
      });
  };

  const sendAnswers = async () => {
    const quizResponse = {
      quizSlug: submittedData.quizSlug,
      quizVersion: submittedData.quizVersion,
      submissionBy: email, //hashed email
      submissionDate: submittedData.submissionDate,
      submittedQuizAnswers: submittedData.submittedQuizAnswers.map((question, questionIdx) => ({
        question: question.question,
        scoreValue: question.scoreValue,
        answer: question.answer,
      })),
    };

    const submitResponse = await submitAnswers({ variables: { input: quizResponse } });
    await setLastQuizResult(submitResponse.data.createSubmission);
  };

  return (
    <Container fluid>
      <Form.Group className="justify-content" controlId="signupFormEmail">
        <Row className="mb-3-col" style={{ paddingTop: "20px", marginBottom: "10px" }}>
          <Col md={3}>
            <Form.Label>Email address</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Control
              role="email"
              type="email"
              value={email}
              onChange={handleChange(setEmail)}
              placeholder="Enter email"
              isValid={validated && !emailError}
              isInvalid={validated && !!emailError}
            />
            <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
            <Form.Control.Feedback type="valid">Looks good.</Form.Control.Feedback>
          </Col>
          <Col md={3}>
            <Button
              variant="primary"
              className="col-12"
              disabled={!!!email}
              onClick={registerUserClick}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <p className="policy-disclaimer" style={{ marginTop: "20px", fontSize: "12px" }}>
        <b>RiverSafe Ltd</b> will use the email you provide to contact you about our products and
        services. You may unsubscribe from these communications at anytime. For information on how
        to unsubscribe, as well as our privacy practices and commitment to protecting your privacy,
        check out our
        <a href="https://riversafe.co.uk/privacy-policy/"> RiverSafe Privacy Policy.</a>
      </p>
    </Container>
  );
}

export default FollowUp;
