import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

// function responsible for tracking page views
const RouteChangeTracker = () => {
  const location = useLocation();

  // The useEffect hook is used to listen for changes in the location
  useEffect(() => {
    ReactGA4.event('page_view', {
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location]);

  return null;
};

export default RouteChangeTracker;
