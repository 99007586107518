import { useContext } from "react";
import { createContext } from "react";
import { useState } from "react";
import { Toast } from "react-bootstrap"

const AlertContext = createContext({
    addAlert: () => { }
})

// functionality
const useShowAlert = () => {
    const { addAlert } = useContext(AlertContext)
    return (text, title = "Error") => addAlert(text, title)
}

// visual component
const AlertContainer = ({ children }) => {
    const [, setId] = useState(1)
    const [alerts, setAlerts] = useState([
    ])

    const closeAlert = (idx) => {
        setAlerts(alerts => {
            return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
        })
    }
    const addAlert = (text, title) => {
        setId(id => id + 1)
        setAlerts(alerts => [...alerts, { title, text }])
    }

    return (
        <>
            <AlertContext.Provider value={{ addAlert }}>
                {children}
            </AlertContext.Provider>
            <div style={{ position: 'fixed', maxWidth: '400px', right: '20px', top: '20px', zIndex: 1 }}>
                {alerts.map((alert, idx) =>
                    <div key={idx} className="d-flex justify-content-end">
                        <Toast bg="primary" onClose={() => closeAlert(idx)} show delay={4000} autohide>
                            <Toast.Header>
                                <img
                                    width={15}
                                    src="/assets/riversafe-logo.svg"
                                    className="me-2"
                                    alt=""
                                />
                                <strong className="me-auto">{alert.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{alert.text}</Toast.Body>
                        </Toast>
                    </div>
                )}
            </div >
        </>
    )
}

export {
    AlertContainer,
    useShowAlert
}
