import React, { useState } from 'react'
import { useForgotPassword } from '../lib/auth-helper';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { checkValidEmail } from '../lib/utils';
import BackButton from '../components/BackButton';


const ForgotPassword = () => {
    const forgotPassword = useForgotPassword()
    const navigate = useNavigate()

    const [username, setUsername] = useState(""); //email
    const [validated, setValidated] = useState(false);

    const forgotPasswordClick = (event) => {
        setValidated(true)
        event.preventDefault();
        event.stopPropagation();
        if (errors.length > 0) {
            return
        }

        forgotPassword(username).then(() => {
            navigate(`/resetPassword?email=${username}`)
        })
    }

    const handleChange = (setState) =>
        (event) => {
            setState(event.target.value);
        }

    const emailError = (
        !username ? 'Please enter an email address.' :
            checkValidEmail(username) ? null : 'Email should be valid. (i.e. john@domain.com)'
    )

    const errors = [
        emailError
    ].filter(i => i)

    return (
        <>
            <Col lg={{ span: 12 }} md={{ span: 6 }}>
                <BackButton redirectTo="login" />
            </Col>
            <Container className="forgot-password" style={{ marginTop: "60px", marginBottom: "40px" }}>
                <Row style={{ minHeight: "100%" }} className="align-items-center">
                    <Col>

                        <Stack className="col-md-6 mx-auto text-center" gap={5}>
                            <h2 style={{ textAlign: 'center' }}>Forgot your password?</h2>
                            <Form className="justify-items-center" validated={validated} noValidate onSubmit={forgotPasswordClick}>
                                <h6 className="text-center">
                                    Please enter your email address below and we will send you an email with instructions
                                    on how to reset your password.
                                </h6>
                                <br />
                                <Form.Group className="mb-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1" controlId="formEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        className='text-center'
                                        type="email"
                                        value={username}
                                        onChange={handleChange(setUsername)}
                                        placeholder="Email address"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid" style={{ fontSize: '0.6em' }}>
                                        {emailError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div>
                                    <Button style={{ width: "300px", marginTop: "2rem" }} variant="primary" type="submit">
                                        Request password reset
                                    </Button>
                                </div>
                            </Form>
                        </Stack>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default ForgotPassword
