import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import { useIsSignedIn } from '../lib/auth-helper'
import TopIconButton from './TopIconButton'


function TopNavBar() {
    const isSignedIn = useIsSignedIn()

    return (
        <Navbar bg="dark" expand="lg">
            <Container fluid>
                <Navbar.Brand >
                    <TopIconButton />
                </Navbar.Brand>
            </Container>
        </Navbar >
    )
}
export default TopNavBar
