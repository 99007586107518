/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission($input: CreateSubmissionInput!) {
    createSubmission(input: $input) {
      quizSlug
      quizVersion
      scoreLevel
      scorePercentage
      scoreSuggestions
      submissionBy
      submissionDate
      submissionId
      submittedQuizAnswers {
        answer
        question
        scoreValue
        __typename
      }
      __typename
    }
  }
`;
