import React from "react";
import { Container, Col, Button, Row, Card, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ResultsPage.scss";
import GaugeChart from "react-gauge-chart";
import { STORAGE_KEYS, useStorageValue } from "../lib/storage";
import IMPROVEMENT_AREAS_DETAILS from "../lib/improvement-areas";
import FollowUp from "../components/FollowUp";

const ResultsPage = () => {
  const { value: answers } = useStorageValue(STORAGE_KEYS.LAST_QUIZ_RESULT);

  // TODO: add coloring condition for possible scores: "Low", "Medium", "High", "Elite"
  const followUpMessage = (
    <>
      As a DevOps professional services provider, RiverSafe helps organisations at all stages of
      their DevOps journey to accelerate their digital transformation and growth. If you want to
      learn more about how we can help, check out our
      <strong>
        <a style={{ color: "var(--dark-primary)" }} href="https://riversafe.co.uk/devopsservices/">
          {" "}
          DevOps services here.
        </a>
      </strong>
    </>
  );

  const improvementAreas = answers?.scoreSuggestions || [];
  const score = answers?.scoreLevel;
  const rating = answers?.scorePercentage;
  const submittedDate = answers?.submissionDate;
  const resultsObj = {};

  const results = improvementAreas.map(
    (improvement) =>
      (resultsObj[improvement] = { improvementArea: improvement, rating: rating, score: score })
  );

  return (
    <>
      <Container className="results"> 
        <h2 className="quiz-title">DevOps Maturity Assesment Results</h2>
        <h5 className="submitted">
          {submittedDate && (
            <div className="text-center">
              <b>Submitted on {submittedDate.substring(0, 19).replace("T", "  ")}</b>
            </div>
          )}
        </h5>

        {Object.keys(results).length > 0 ? (
          <>
            <div className="score-section">
              <Row style={{ display: "flex" }}>
                <Col className="text-start">
                  <h3>Software delivery performance</h3>
                  <br />
                  <h5>Your score was:</h5>
                  <h3 className="score">{score.toLocaleUpperCase()}</h3>
                </Col>
                <div className="col-lg-6 col-lg-offset-1 col-12">
                  <GaugeChart
                    id="gauge-chart6"
                    nrOfLevels={10}
                    percent={rating / 100}
                    animate={true}
                    textColor="var(--dark)"
                    arcPadding={0.03}
                    arcWidth={0.2}
                    needleBaseColor={"var(--light-gray)"}
                    needleColor={"var(--light-gray)"}
                    fontSize={"2.5rem"}
                    marginInPercent={0.03}
                    cornerRadius={10}
                    colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                    formatTextValue={(value) => value + "%"}
                    // arcsLength={[0.3, 0.5, 0.2]}
                  />
                </div>
              </Row>
            </div>
            <Row>
              <h5 style={{ marginTop: "30px", marginBottom: "15px" }}>
                <b>Key DevOps Improvement Areas</b>
              </h5>
              <br />
            </Row>
            <Row>
              {results.map((resultObj, idx) => (
                <Col className="col-xs-12 col-sm-12 col-md-4 card-box" key={idx}>
                  <Card>
                    <Card.Img
                      src={IMPROVEMENT_AREAS_DETAILS[resultObj.improvementArea].image}
                    ></Card.Img>
                    <Card.Title className="text-dark" style={{ color: "var(--background-gray)" }}>
                      {resultObj.improvementArea.replace("_", " ")}
                    </Card.Title>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="col-xs-12 col-sm-12">
              <i style={{ marginTop: "20px" }}>
                If you want to learn more about how to improve your <b>Key DevOps Areas</b>, just
                enter your email below and we’ll send you personalised solutions!.
              </i>
              <FollowUp data={answers} />
            </Row>
            <br />
            <Alert variant="secondary">
              <span>
                <i className="bi bi-info-circle-fill"></i>
              </span>
              {followUpMessage}
            </Alert>
          </>
        ) : (
          <Row>
            <h4>
              <span>-</span> No results to show <span>-</span>
            </h4>
          </Row>
        )}
        <Row className="justify-content-center mt-4">
          <Col className="col-md-4 col-lg-3">
            <Button style={{ marginBottom: "20px" }}>
              <Link to="/quiz">Take the quiz again</Link>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResultsPage;
