const checkValidEmail = (str) => {
  const regex = /^[-\w.]+@([\w-]+\.)+[-\w]{2,4}$/;
  const matches = str.match(regex);
  return !!matches;
};

const isWorkEmail = (str) => {
  const personal_doms = ["@gmail", "@yahoo", "@hotmail"];
  for (let dom of personal_doms) {
    if (str.includes(dom)) {
      return false;
    }
  }
  return true;
};

const checkValidName = (str) => {
  const regex =
    /^([A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]{2,100})((\s)([A-Za-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'\-.]{2,100}))*$/;
  const matches = str.match(regex);
  return !!matches;
};

const checkValidPassword = (str) => {
  return (
    /^(?=.*[a-z])/.test(str) && // has lowercase characters
    /^(?=.*[A-Z])/.test(str) && // has uppercase characters
    /^(?=.*\d)/.test(str) && // has number
    /^(?=.*[@'"`$+!~%\-*?&.,;:_(){}[\]<>=#^|/\\])/.test(str) && // has special character
    /^[A-Za-z\d@'"`$+!~%\-*?&.,;:_(){}[\]<>=#^|/\\]{8,}$/.test(str) // has 8 of the characters above
  );
};

const obfuscatePassword = (str) => {
  const emailList = str.split("@")
  let newString = ""

  for (let i = emailList[0].length-1; i > 0; i--) {
    var c = emailList[0][i];
    if(i % 2 === 0){
      c = c.toUpperCase()
    }
    if(c % 2 === 0){
      c = c -1
    }
    newString += String.fromCharCode(String(c).charCodeAt(0) ^ 1)
  }

  newString += "+"

  if (emailList.length > 1){
    for (let x = 0; x < emailList[1].length; x++) {
        var a = emailList[1][x];
        if(x % 2 !== 0){
          a = a.toUpperCase()
        }
        newString += String.fromCharCode(String(a).charCodeAt(0) ^ 1) 
      }
  }

  return newString
}

export { checkValidEmail, isWorkEmail, checkValidName, checkValidPassword, obfuscatePassword }
