import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './LoginPage.scss'
import SignUp from './SignUp.js'
import SignIn from './SignIn.js'

const LoginPage = ({ login = false, signup = false }) => {

    return (
        <>
            <Container fluid md={{ span: 12 }} className="login-container">
                <Row className="form-display">
                    <Col lg={{ span: 5 }} md={{ span: 12 }} className="gradient-custom-left">
                        <div>
                            <img
                                src="/assets/riversafe-logo.svg"
                                id="riversafe-logo"
                                alt="riversafe-logo"
                            />
                            <br />
                            <img
                                src="/assets/riversafe-banner.svg"
                                id="riversafe-banner"
                                alt="riversafe-banner"
                            />
                        </div >
                    </Col >
                    <Col lg={{ span: 7 }} md={{ span: 12 }} className=" gradient-custom-right">
                        {login ? <SignIn /> : <SignUp />}
                    </Col>
                </Row >
            </Container >
        </>
    );
}

export default LoginPage
