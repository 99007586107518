import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./lib/apollo-client";
import { UserProvider } from "./lib/auth-helper";
import { AlertContainer } from "./lib/alert";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteChangeTracker from "./RouteChangeTracker";

const WithProvider = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <AlertContainer>
        <UserProvider>
          <App />
        </UserProvider>
      </AlertContainer>
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RouteChangeTracker />
      <Routes>
        <Route path="*" element={<WithProvider />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
