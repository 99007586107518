import { Auth } from "aws-amplify";
import { createContext, useState, useEffect, useContext } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Button } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";

// Ref doc: https://docs.aws.amazon.com/cognito/latest/developerguide/login-endpoint.html
//          https://docs.amplify.aws/lib/auth/social/q/platform/js/#configure-auth-category
//          https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
Auth.configure({
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  aws_project_region: process.env.REACT_APP_COGNITO_REGION,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  oauth: {
    responseType: "code",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
    redirectSignIn: process.env.REACT_APP_FRONT_END_URL + "/quiz",
    redirectSignOut: process.env.REACT_APP_FRONT_END_URL,
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
});

const UserContext = createContext({
  user: null,
  loading: true,
  setUser: () => {},
});

/**
 * @returns Promise<CognitoUser>
 */
const getCurrentUser = () => {
  return Auth.currentAuthenticatedUser().then((authData) => {
    return authData;
  });
};

/**
 * @returns Promise<Boolean>
 */
const useIsSignedIn = () => {
  const { user } = useContext(UserContext);
  return user != null || false;
};

const useUserAttributes = () => {
  const { user } = useContext(UserContext);
  if (!user || !user.attributes) {
    return {};
  }
  return {
    id: user.attributes.sub,
    identities: user.attributes.identities,
    emailVerified: user.attributes.email_verified,
    name: user.attributes.name,
    email: user.attributes.email,
    username: user.username,
    companyName: user.attributes["custom:organisation_name"],
    photo: user.attributes.picture,
  };
};

const getJwtToken = () => {
  return Auth.currentSession().then((res) => {
    return res.getIdToken().getJwtToken();
  });
};

const useSignIn = () => {
  const { setUser, setLoading } = useContext(UserContext);
  return async (username, password) => {
    setLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      setUser(user);
    } finally {
      setLoading(false);
    }
  };
};

const useSignUp = () => {
  const { setUser, setLoading } = useContext(UserContext);
  return async (params) => {
    setLoading(false);
    try {
      const { userConfirmed, user } = await Auth.signUp(params);
      if (userConfirmed) {
        setUser(user);
      }
      return { userConfirmed, user };
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
};

const useRegisterUser = () => {
  return async (params) => {
    const { userConfirmed, user } = await Auth.signUp(params);
    return { userConfirmed, user };
  };
};

const useSignOut = () => {
  const { setUser } = useContext(UserContext);
  return () => Auth.signOut().then(() => setUser(null));
};

const useForgotPassword = () => {
  return (username) => Auth.forgotPassword(username).catch((err) => console.log(err));
};

const useResendVerificationCode = () => {
  return async (email) => {
    try {
      const response = await Auth.resendSignUp(email);
      return response;
    } catch (err) {
      throw err;
    }
  };
};

const useVerifyUserEmail = () => {
  return async (email, code) => {
    return await Auth.confirmSignUp(email, code).catch((err) => {
      throw err;
    });
  };
};

const useResetPassword = () => {
  return async (email, code, newPassword) => {
    return await Auth.forgotPasswordSubmit(email, code, newPassword).catch((err) => {
      throw err;
    });
  };
};

const UserProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getCurrentUser()
      .then(setAuthData)
      .catch((err) => {
        setAuthData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <UserContext.Provider
      value={{ user: authData, loading: loading, setUser: setAuthData, setLoading }}
    >
      {children}
    </UserContext.Provider>
  );
};

const ProtectedLayout = ({ needsUser = false, noUser = false, redirectTo = null }) => {
  const { user } = useContext(UserContext);
  const hasSession = user?.signInUserSession;
  if (needsUser && !hasSession) {
    return <Navigate to={redirectTo}></Navigate>;
  }
  if (noUser && hasSession) {
    return <Navigate to={redirectTo}></Navigate>;
  }
  return <Outlet replace />;
};

/**
 * https://docs.amplify.aws/lib/auth/advanced/q/platform/js/#google-sign-in-react
 * @returns A component that triggers an auth with Google
 */
const SignInWithGoogleButton = ({ shape = "pill", type = "icon" }) => {
  const signInWithGoogle = () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  return (
    <>
      <Button className="btn-primary col-12" onClick={signInWithGoogle}>
        <img
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "2px",
            width: "1.5rem",
          }}
          src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
          alt="Google icon"
        />
        &nbsp;&nbsp; Sign in with Google
      </Button>
    </>
  );
};

// Ref: https://aws.amazon.com/premiumsupport/knowledge-center/cognito-linkedin-auth0-social-idp/
const SignInWithLinkedinButton = () => {
  const linkedInLogin = () => {
    Auth.federatedSignIn({
      provider: "LinkedIn-Auth0",
    }).catch((e) => {
      throw e;
    });
  };
  return (
    <>
      <Button className="btn-primary col-12" onClick={linkedInLogin}>
        <img
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "2px",
            width: "1.25rem",
          }}
          src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
          alt="Linkedin icon"
        ></img>
        &nbsp;&nbsp; Sign in with LinkedIn
      </Button>
    </>
  );
};

export {
  getCurrentUser,
  useSignIn,
  useSignUp,
  useSignOut,
  useRegisterUser,
  useForgotPassword,
  useResetPassword,
  getJwtToken,
  useIsSignedIn,
  useResendVerificationCode,
  useVerifyUserEmail,
  UserProvider,
  SignInWithGoogleButton,
  SignInWithLinkedinButton,
  ProtectedLayout,
  useUserAttributes,
  UserContext,
};
