import React from 'react'
import { Link } from 'react-router-dom'
import './BackButton.scss'

function BackButton(props) {
    const redirectTo = props.redirectTo || ""

    return (
        <div className="back-nav">
            <Link data-testid="back-button" id="link" to={`/${redirectTo}`}>
                <span><i className="bi bi-arrow-left-short"></i></span>
                Back
            </Link>
        </div>
    )
}

export default BackButton
