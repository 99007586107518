import React from 'react'
import { Link } from 'react-router-dom'

function TopIconButton() {

    const homepageUrl = "https://riversafe.co.uk/devopsservices/devops-maturity-tool/"
    const customLogoStyle = {
        padding: "0.5rem",
        cursor: "pointer",
        width: "9em",
        marginLeft: "0.1rem",
        marginTop: "0.1rem"
    }

    return (
        <div className="text-start">
            <Link to={homepageUrl}>
                <img style={customLogoStyle} alt="home button" src="/assets/riversafe-banner.svg" />
            </Link>
        </div>
    )
}

export default TopIconButton
