import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Stack, Button, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSignUp, SignInWithGoogleButton, SignInWithLinkedinButton } from '../lib/auth-helper';
import { useShowAlert } from '../lib/alert';
import { Link, useNavigate } from 'react-router-dom';
import { checkValidEmail, checkValidName, checkValidPassword } from '../lib/utils';

const SignUp = () => {

    const signUp = useSignUp() // returns a function()
    const showAlert = useShowAlert()
    const navigate = useNavigate()

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isPolicyAgreed, setPolicyAgreed] = useState(false);
    const [validated, setValidated] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState("password")
    const passwordPolicyTxt =
        <div style={{ textAlign: "left" }}>
            <strong>Must contain at least:</strong>
            <br />
            <li>1 number</li>
            <li>1 special character</li>
            <li>1 uppercase letter</li>
            <li>1 lowercase letter</li>
            <li>8 characters long</li>
        </div>

    const handleChange = (setState) =>
        (event) => {
            setState(event.target.value);
        }

    const signUpClick = (event) => {
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        if (errors.length > 0) {
            return
        }

        let signUpParams = {
            'username': username,
            'password': password,
            'attributes': {
                "name": customerName,
                "custom:organisation_name": companyName,
            }
        }

        signUp(signUpParams)
            .then(({ userConfirmed }) => {
                showAlert("Registration successful", "Success");
                if (!userConfirmed) {
                    navigate(`/verification?email=${username}`);
                }
            }).catch((error) => {
                if (error.code === 'UsernameExistsException') {
                    showAlert("Invalid registration request")
                }
                else showAlert(error.message)
            })
    }

    const togglePassword = () => {
        setPasswordInputType((currentType) => {
            return currentType === "password" ? "text" : "password"
        })
    }

    const emailError = (
        !username ? 'Please enter an email address.' :
            checkValidEmail(username) ? null : 'Email should be valid. (i.e. john@domain.com)'
    )

    const passwordError = !password ? "Please enter a password" :
        checkValidPassword(password) ? null : "Password should match the policy"

    const customerNameError = !customerName ? "Please enter a name." :
        checkValidName(customerName) ? null : "Name is not valid."

    const companyNameError = companyName ? null : "Please enter a company name."

    const errors = [
        emailError,
        passwordError,
        customerNameError,
        companyNameError,
    ].filter(i => i)

    const renderTooltip = (props) => {
        return <Tooltip {...props}>
            <span>{passwordPolicyTxt}</span>
        </Tooltip>
    }

    return (
        <Container style={{ display: "contents" }}>
            <Row className="align-items-center" style={{ minHeight: "87%", marginBottom: '30px' }} >
                <Col>
                    <h2>Sign up</h2>
                    <Stack className='col-md-12 col-lg-12 col-xl-9 mx-auto' gap={2}>
                        <Form noValidate onSubmit={signUpClick} style={{ marginBottom: "-10px" }}>
                            <Container style={{ padding: 0 }}>
                                <Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="signupFormName">
                                    <Form.Label column md={5}>Name</Form.Label>
                                    <Col md={6}>
                                        <Form.Control
                                            type="text"
                                            value={customerName}
                                            onChange={handleChange(setCustomerName)}
                                            placeholder="Enter name"
                                            isValid={validated && !customerNameError}
                                            isInvalid={validated && !!customerNameError}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {customerNameError}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">
                                            Looks good.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="signupFormCompanyName">
                                    <Form.Label column md={5}>Company Name</Form.Label>
                                    <Col md={6}>
                                        <Form.Control
                                            role="companyName"
                                            type="text"
                                            value={companyName}
                                            onChange={handleChange(setCompanyName)}
                                            placeholder="Enter company name"
                                            isValid={validated && !companyNameError}
                                            isInvalid={validated && !!companyNameError}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {companyNameError}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">
                                            Looks good.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="signupFormEmail">
                                    <Form.Label column md={5}>Email address</Form.Label>
                                    <Col md={6}>
                                        <Form.Control
                                            role="email"
                                            type="email"
                                            value={username}
                                            onChange={handleChange(setUsername)}
                                            placeholder="Enter email"
                                            isValid={validated && !emailError}
                                            isInvalid={validated && !!emailError}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {emailError}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">
                                            Looks good.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="signupFormPassword">
                                    <Form.Label column md={5}>Password&nbsp;&nbsp;
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={renderTooltip}
                                        >
                                            <i className="bi bi-info-circle"></i>
                                        </OverlayTrigger>
                                    </Form.Label>

                                    <Col md={6}>
                                        <InputGroup>
                                            <Form.Control
                                                type={passwordInputType}
                                                value={password}
                                                role="password"
                                                onChange={handleChange(setPassword)}
                                                placeholder="Enter password"
                                                isValid={validated && !passwordError}
                                                isInvalid={validated && !!passwordError}
                                            />
                                            <InputGroup.Text>
                                                <i className={passwordInputType === "password" ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"} style={{ cursor: "pointer" }} onClick={togglePassword} data-testid="visibilityIcon"></i>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        {passwordError && validated
                                            ? <div className="invalid-feedback" style={{ display: 'block' }}>{passwordError}</div>
                                            : null
                                        }
                                    </Col>
                                </Form.Group>
                                <Row className="justify-content-md-center">
                                    <Col md={11}>
                                        <p className="policy-disclaimer"><b>RiverSafe Ltd</b> needs the contact information you provide to us to contact you about our products and services.
                                            You may unsubscribe from these communications at anytime. For information on how to unsubscribe,
                                            as well as our privacy practices and commitment to protecting your privacy, check out our Privacy Policy.
                                        </p>
                                        <div className="form-check" style={{ display: "flex" }}>
                                            <input className="form-check-input" type="checkbox" value="" id="acceptCheckbox" onClick={() => setPolicyAgreed(!isPolicyAgreed)} />
                                            <label className="user-check-label" htmlFor="acceptCheckbox">
                                                I have read and accept the <a href="https://riversafe.co.uk/privacy-policy/"> RiverSafe Privacy Policy.</a>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col md={11}>
                                        <Button variant="primary" className="col-12" type="submit" disabled={!!!isPolicyAgreed}>
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Form >
                        <div className="divider d-flex align-items-center my-3">
                            <p className="text-center mx-3 mb-0">or</p>
                        </div>

                    </Stack >
                    <Row className="gap-2 text-center justify-content-md-center">
                        <Col xs={12} md={{ span: 7 }} lg={8} xl={{ span: 4 }}>
                            <SignInWithGoogleButton type="standard" />
                        </Col>
                        <Col xs={12} md={{ span: 7 }} lg={8} xl={{ span: 4 }}>
                            <SignInWithLinkedinButton />
                        </Col>
                    </Row>
                </Col >
            </Row >
            <Row style={{ textSize: '0.5rem', position: 'relative', marginBottom: "1rem" }}>
                <Col className="text-center">
                    <p>Already have an account?&nbsp;&nbsp;
                        <Link to="/login">Log in here</Link></p>
                </Col>
            </Row>
        </Container >
    )
}

export default SignUp
